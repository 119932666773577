.haidorybannersection {
  width: 100%;
  min-height: 520px;

  position: relative;
  background: url(../../assets/images/haidory-banner.png) no-repeat center
    center;
  background-size: cover;
  padding-bottom: 70px;
}
section.haidorybannersection:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(
    222,
    208,
    131,
    0.6
  ); */

  /* Adjust the color and opacity as needed */
  /* Place the overlay above the image (you can adjust this value) */
}

.haidorycompanyslide {
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 19px;
  min-height: 120px;
  /* float: left; */
  width: 100%;
  /* padding-top: 100px; */
  border: dashed 1px #9fafb9;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3px;
}

.challengerscompanyslide {
  margin-top: 1rem;
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 19px;
  min-height: 120px;
  float: left;
  width: 100%;
  /* padding-top: 100px; */
  border: dashed 1px #9fafb9;
}

@media (min-width: 200px) and (max-width: 991px) {
  .haidorybannersection {
    min-height: 250px;
  }
}
