.companyslidehome {
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 23px;
  min-height: 100px;
  float: left;
  width: 101%;
  /* padding-top: 100px; */
  border: dashed 1px #9fafb9;
}
/* .companysliderhome {
  width: 117px;
  margin: auto;
  display: table;
} */
.slidericon {
  width: 100%;
  margin: auto;
  display: table;
}
.companyslidetrade {
  background: #ffffff;
  box-shadow: 2px 0px 20px 8px rgb(185 197 205 / 21%);
  border-radius: 14px;
  padding: 19px;
  min-height: 120px;
  float: left;
  width: 100%;
  /* padding-top: 100px; */
  border: dashed 1px #9fafb9;
}
.ullist {
  margin-top: -3px !important;
  padding: 0px;
  list-style: none;
  margin-top: 22px;
  width: 100%;
  float: left;
}

/* @media (min-width: 800px) and (max-width: 1300px) {

  .sister-para{
  
  }
} */
